var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"modal fade",attrs:{"id":"modal-form-estado"}},[_c('div',{staticClass:"modal-dialog modal-md"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v("Actualizar Estado")]),_c('button',{ref:"closeModal",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"estado"}},[_vm._v("Estado")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form_estado.estado),expression:"form_estado.estado"}],staticClass:"form-control form-control-sm",class:_vm.$v.form_estado.estado.$invalid ? 'is-invalid' : 'is-valid',attrs:{"id":"estado"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form_estado, "estado", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.ajustaCamposEstado]}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.$parent.listasForms.estados),function(estado){return _c('option',{key:estado.numeracion,domProps:{"value":estado.numeracion}},[_vm._v(" "+_vm._s(estado.descripcion)+" ")])})],2)]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"justificacion"}},[_vm._v("Justificación")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form_estado.justificacion),expression:"form_estado.justificacion"}],staticClass:"form-control form-control-sm",class:_vm.$v.form_estado.justificacion.$invalid
                  ? 'is-invalid'
                  : 'is-valid',attrs:{"cols":"30","rows":"5"},domProps:{"value":(_vm.form_estado.justificacion)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form_estado, "justificacion", $event.target.value)}}})]),(
              _vm.form_estado.estado == 2
            )?_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Fecha Reactivación")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form_estado.fecha_reactivacion),expression:"form_estado.fecha_reactivacion"}],staticClass:"form-control form-control-sm",class:_vm.$v.form_estado.fecha_reactivacion
                  ? _vm.$v.form_estado.fecha_reactivacion.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                  : '',attrs:{"type":"date"},domProps:{"value":(_vm.form_estado.fecha_reactivacion)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form_estado, "fecha_reactivacion", $event.target.value)},_vm.validaFechas]}})]):_vm._e()]),_c('div',{staticClass:"modal-footer justify-content-between"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.form_estado.$invalid),expression:"!$v.form_estado.$invalid"}],staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Guardar ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }